












import {
  computed, toRefs, defineComponent,
} from '@nuxtjs/composition-api';
import { ISbStoryData, ISbRichtext } from '@storyblok/js/dist/types';
import { useStoryblokStyles } from '~/modules/storyblok/composables';
import { renderRichText } from '~/modules/storyblok/helpers/renderRichText';

export default defineComponent({
  name: 'RichText',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { blok } = toRefs(props);
    const { pcAlign, tabletAlign, align } = blok.value;
    const { centerTextStyle } = useStoryblokStyles();
    const centerTextClass = computed(() => centerTextStyle({ pcAlign, tabletAlign, align }));
    // eslint-disable-next-line @typescript-eslint/dot-notation
    const richtext = computed((): ISbRichtext => renderRichText(blok.value['RichText'] as ISbStoryData));
    return {
      richtext,
      centerTextClass,
    };
  },
});
